import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { delay, finalize } from 'rxjs';

export const spinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const _loading = inject(NgxSpinnerService);

  const excludedUrls = ['empresa-refresh', 'clases', 'permisos'];
  if (excludedUrls.some((url) => req.url.includes(url))) {
    return next(req);
  }
  _loading.show('main-spinner');

  return next(req).pipe(
    delay(300),
    finalize(async () => await _loading.hide('main-spinner')),
  );
};
