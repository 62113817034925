<router-outlet></router-outlet>

<ngx-spinner name="main-spinner" type="ball-spin" size="medium">
  <p style="font-size: 16px; color: white">Cargando...</p>
</ngx-spinner>

<p-toast
  key="toast"
  [life]="2000"
  [breakpoints]="{ '920px': { width: '100%', right: '0', left: '0' } }"
></p-toast>
<p-confirmDialog key="confirm" />
