import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpHandlerFn,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { MessageService } from 'primeng/api';
import { catchError, Observable, throwError } from 'rxjs';

export const responseInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
  const _messageService = inject(MessageService);

  return next(req).pipe(
    catchError((httpErrorResponse: HttpErrorResponse) => {
      _messageService.add({
        key: 'toast',
        detail:
          httpErrorResponse.error.message ?? 'Lo sentimos algo ha salido mal',
        severity: 'error',
        summary: 'Error',
      });

      return throwError(() => httpErrorResponse);
    })
  );
};
